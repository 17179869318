var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tabContent active", attrs: { id: "dadosCadastrais" } },
    [
      _c(
        "vs-row",
        [
          _c(
            "vs-col",
            { staticClass: "w-full md:w-1/2 lg:w-1/3" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: ["##.###.###/####-##"],
                    expression: "['##.###.###/####-##']"
                  }
                ],
                attrs: {
                  label: "CNPJ",
                  disabled: "true",
                  value: _vm._f("maskCnpj")(
                    _vm.corretor.BrokerDocument || _vm.corretor.CpfCnpj
                  ),
                  size: "large"
                }
              })
            ],
            1
          ),
          _c(
            "vs-col",
            { staticClass: "w-full md:w-1/2 lg:w-2/3" },
            [
              _c("vs-input", {
                attrs: {
                  label: "Razão Social",
                  disabled: !_vm.podeAlterar,
                  size: "large"
                },
                model: {
                  value: _vm.brokerName,
                  callback: function($$v) {
                    _vm.brokerName = $$v
                  },
                  expression: "brokerName"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vs-row",
        [
          _c(
            "vs-col",
            { staticClass: "w-full md:w-1/3" },
            [
              _c("vs-input", {
                attrs: {
                  label: "Inscrição Municipal",
                  disabled: !_vm.podeAlterar,
                  size: "large"
                },
                on: {
                  blur: function($event) {
                    return _vm.updateInscricaoMunicipal()
                  }
                },
                model: {
                  value: _vm.corretor.InscricaoMunicipal,
                  callback: function($$v) {
                    _vm.$set(_vm.corretor, "InscricaoMunicipal", $$v)
                  },
                  expression: "corretor.InscricaoMunicipal"
                }
              })
            ],
            1
          ),
          _c(
            "vs-col",
            { staticClass: "w-full md:w-1/3" },
            [
              _c("vs-input", {
                attrs: {
                  label: "Comissão",
                  disabled: !_vm.perfilHelpers.isInsurance(),
                  size: "large",
                  icon: "%"
                },
                model: {
                  value: _vm.broker.ComissionPercentage,
                  callback: function($$v) {
                    _vm.$set(_vm.broker, "ComissionPercentage", $$v)
                  },
                  expression: "broker.ComissionPercentage"
                }
              })
            ],
            1
          ),
          _vm.perfilHelpers.isInsurance()
            ? _c(
                "vs-col",
                { staticClass: "w-full md:w-1/4" },
                [
                  _c("vs-input", {
                    attrs: {
                      label: "Limite de Comissão",
                      disabled: !_vm.perfilHelpers.isInsurance(),
                      size: "large",
                      icon: "%"
                    },
                    model: {
                      value: _vm.broker.MaxComissionPercentage,
                      callback: function($$v) {
                        _vm.$set(_vm.broker, "MaxComissionPercentage", $$v)
                      },
                      expression: "broker.MaxComissionPercentage"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "vs-col",
            { staticClass: "w-full md:w-1/3" },
            [
              _c("vs-input", {
                attrs: {
                  label: "Situação do CNPJ",
                  disabled: "true",
                  size: "large"
                },
                model: {
                  value: _vm.corretor.CNAESituation,
                  callback: function($$v) {
                    _vm.$set(_vm.corretor, "CNAESituation", $$v)
                  },
                  expression: "corretor.CNAESituation"
                }
              })
            ],
            1
          ),
          _c(
            "vs-col",
            { staticClass: "w-full md:w-1/3 py-4" },
            [
              _c("vs-input", {
                attrs: {
                  label: "Código CNAE",
                  disabled: "true",
                  value:
                    _vm.corretor.CNAECode ||
                    ((_vm.corretor.CNAEs || [])[0] || {}).Code,
                  size: "large"
                }
              })
            ],
            1
          ),
          _c(
            "vs-col",
            { staticClass: "w-full md:w-2/3 py-4" },
            [
              _c("vs-input", {
                attrs: {
                  label: "Ramo",
                  disabled: "true",
                  value:
                    _vm.corretor.CNAEBranch ||
                    ((_vm.corretor.CNAEs || [])[0] || {}).Description,
                  size: "large"
                }
              })
            ],
            1
          ),
          _c(
            "vs-col",
            { staticClass: "w-full md:w-1/3" },
            [
              _c("vs-input", {
                attrs: {
                  label: "Data do registro",
                  disabled: "true",
                  value: _vm._f("moment")(
                    new Date(
                      _vm.corretor.CreateDate || _vm.corretor.SUSEPRegisterDate
                    ),
                    "DD/MM/YYYY"
                  ),
                  size: "large"
                }
              })
            ],
            1
          ),
          _c(
            "vs-col",
            { staticClass: "w-full md:w-1/3" },
            [
              _vm.corretor.RevalidationDate
                ? _c("vs-input", {
                    attrs: {
                      label: "Data do recadastro",
                      disabled: "true",
                      value: _vm._f("moment")(
                        new Date(_vm.corretor.RevalidationDate),
                        "DD/MM/YYYY"
                      ),
                      size: "large"
                    }
                  })
                : _c("vs-input", {
                    attrs: {
                      label: "Data do recadastro",
                      disabled: "true",
                      value: "Sem Registro",
                      size: "large"
                    }
                  })
            ],
            1
          ),
          _c(
            "vs-col",
            { staticClass: "w-full md:w-1/3" },
            [
              _c("vs-input", {
                attrs: {
                  label: "Data expiração do cadastro",
                  disabled: "true",
                  value: _vm.corretor.ExpirationDate
                    ? _vm.expirationDate
                    : "Sem registro",
                  size: "large"
                }
              })
            ],
            1
          ),
          _c(
            "vs-col",
            { staticClass: "w-full md:w-1/3 pt-4" },
            [
              _c("vs-input", {
                attrs: {
                  label: "Situação SUSEP",
                  disabled: "true",
                  value: _vm.situacaoSusep,
                  size: "large"
                }
              })
            ],
            1
          ),
          _c(
            "vs-col",
            { staticClass: "w-full md:w-1/3 pt-4" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: {
                  label: "Codigo SUSEP",
                  disabled: "true",
                  value: _vm.corretor.SUSEPProtocol,
                  size: "large"
                }
              })
            ],
            1
          ),
          _c(
            "vs-col",
            { staticClass: "w-full md:w-1/3 pt-4" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: ["(##) ####-####", "(##) #####-####"],
                    expression: "['(##) ####-####', '(##) #####-####']"
                  }
                ],
                attrs: {
                  label: "Telefone",
                  disabled:
                    !_vm.perfilHelpers.isInsurance() || !_vm.podeAlterar,
                  size: "large"
                },
                model: {
                  value: _vm.broker.PhoneNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.broker, "PhoneNumber", $$v)
                  },
                  expression: "broker.PhoneNumber"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("form-endereco", {
        attrs: {
          dadosEndereco: _vm.address,
          disabled: !_vm.perfilHelpers.isInsurance() || !_vm.podeAlterar
        },
        on: { updateValue: _vm.updateLocationValue }
      }),
      _c("DadosComplementaresCadastroMapfre", {
        attrs: {
          permissoesCorretorMapfre: _vm.permissoesCorretorMapfre,
          broker: _vm.broker,
          tiposDeCanais: _vm.tiposDeCanais
        }
      }),
      !_vm.corretor.IsActive && _vm.perfilHelpers.isInsurance()
        ? _c("vs-row", [
            _c(
              "div",
              { staticClass: "vx-col pt-5 w-full mt-2" },
              [
                _c("label", [_vm._v("Justificativa de bloqueio")]),
                _c("vs-textarea", {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.corretor.BlockJustify,
                    callback: function($$v) {
                      _vm.$set(_vm.corretor, "BlockJustify", $$v)
                    },
                    expression: "corretor.BlockJustify"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.podeAlterar
        ? _c(
            "vs-row",
            { staticClass: "mt-5", attrs: { "vs-justify": "flex-end" } },
            [
              _c(
                "vs-button",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.atualizarDados()
                    }
                  }
                },
                [
                  _c("span", { staticClass: "font-semibold" }, [
                    _vm._v(" Salvar ")
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }