var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.podeExibirDadosComplementares
    ? _c(
        "vs-row",
        { staticClass: "pt-0" },
        [
          _c(
            "vs-col",
            { staticClass: "w-full md:w-1/4 pt-4" },
            [
              _c("vs-input", {
                attrs: {
                  disabled: !_vm.perfilHelpers.isInsurance(),
                  label: "Código corretor mapfre",
                  size: "large",
                  maxlength: "10"
                },
                on: {
                  input: function($event) {
                    return _vm.validarNumeros($event, "broker.CodigoCorretor")
                  }
                },
                model: {
                  value: _vm.broker.CodigoCorretor,
                  callback: function($$v) {
                    _vm.$set(_vm.broker, "CodigoCorretor", $$v)
                  },
                  expression: "broker.CodigoCorretor"
                }
              })
            ],
            1
          ),
          _c(
            "vs-col",
            { staticClass: "w-full md:w-1/4 pt-4" },
            [
              _c("vs-input", {
                attrs: {
                  disabled: !_vm.perfilHelpers.isInsurance(),
                  label: "Código sucursal",
                  size: "large",
                  maxlength: "10"
                },
                on: {
                  input: function($event) {
                    return _vm.validarNumeros($event, "broker.CodigoSurcursal")
                  }
                },
                model: {
                  value: _vm.broker.CodigoSurcursal,
                  callback: function($$v) {
                    _vm.$set(_vm.broker, "CodigoSurcursal", $$v)
                  },
                  expression: "broker.CodigoSurcursal"
                }
              })
            ],
            1
          ),
          _c(
            "vs-col",
            { staticClass: "w-full md:w-1/4 pt-4" },
            [
              _c("vs-input", {
                attrs: {
                  disabled: !_vm.perfilHelpers.isInsurance(),
                  label: "Código contrato",
                  size: "large",
                  maxlength: "10"
                },
                on: {
                  input: function($event) {
                    return _vm.validarNumeros($event, "broker.CodigoContrato")
                  }
                },
                model: {
                  value: _vm.broker.CodigoContrato,
                  callback: function($$v) {
                    _vm.$set(_vm.broker, "CodigoContrato", $$v)
                  },
                  expression: "broker.CodigoContrato"
                }
              })
            ],
            1
          ),
          _c(
            "vs-col",
            { staticClass: "w-full md:w-1/4 pt-4" },
            [
              _c("ValidationProvider", {
                attrs: { name: "canal", rules: "required", tag: "vs-col" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var errors = ref.errors
                        return [
                          _c(
                            "vs-select",
                            {
                              staticClass: "large w-full border-select",
                              attrs: {
                                disabled: !_vm.perfilHelpers.isInsurance(),
                                label: "Canal",
                                value: _vm.broker.TipoCanalId,
                                reduce: function(tipoCanal) {
                                  return tipoCanal.Id
                                }
                              },
                              model: {
                                value: _vm.broker.TipoCanalId,
                                callback: function($$v) {
                                  _vm.$set(_vm.broker, "TipoCanalId", $$v)
                                },
                                expression: "broker.TipoCanalId"
                              }
                            },
                            _vm._l(_vm.tiposDeCanais, function(
                              tipoDeCanal,
                              index
                            ) {
                              return _c("vs-select-item", {
                                key: index,
                                attrs: {
                                  value: tipoDeCanal.Id,
                                  text: tipoDeCanal.Descricao
                                }
                              })
                            }),
                            1
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: errors[0],
                                  expression: "errors[0]"
                                }
                              ],
                              staticClass: "text-danger text-sm"
                            },
                            [
                              _vm._v(
                                "\n        " + _vm._s(errors[0]) + "\n      "
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1012084545
                )
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }